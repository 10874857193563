import styled from "styled-components";

import { Table } from "antd";

export const EMDraggableTableV2 = styled(Table)`
    width: 100%;

    border-collapse: collapse;

    overflow: hidden;

    thead {
        border-bottom: 1px solid ${(props) => props.theme.colorVioletGray} !important;

        tr {
            th {
                padding: 5px 5px 5px 0 !important;

                text-align: left;
                vertical-align: middle;

                font-size: 14px;
                font-weight: 600;
                color: ${props => props.theme.colorVioletGray} !important;

                background: transparent !important;
                border-bottom: 1px solid ${(props) => props.theme.colorVioletGray} !important;

                &:first-child {
                    width: 30px;
                }
            }

            td {
                background: transparent !important;
                border-bottom: 1px solid ${(props) => props.theme.colorVioletGray} !important;

                &:first-child {
                    width: 30px;
                }
            }
        }
    }

    tbody {
        tr {
            &.ant-table-placeholder {
                background: transparent !important;
            }
            
            &:hover {
                td {
                    background: transparent !important;
                }
            }
            
            td {
                padding: 5px 5px 5px 0 !important;

                vertical-align: ${(props) => props.verticalAlign || "middle"};

                font-size: 14px;
                font-weight: 400;
                color: ${props => props.theme.colorVioletGray} !important;

                border-bottom: none !important;
            }
        }
    }
`;