import React from "react";
import styled from "styled-components";

import { Spin } from "antd";

const Loader = () => {
    return (
        <Wrapper>
            <Spin />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100vw;
    
    padding: 50px 0;

    display: flex;
    justify-content: center;
`;

export default Loader;