import styled from "styled-components";
import { rgba } from "polished";

import AngleUpIcon from "../../../assets/images/common/chevron-up.svg";
import AngleDownIcon from "../../../assets/images/common/chevron-down.svg";

export const EMTableV1 = styled.table`
    width: 100%;

    box-shadow: 0 5px 8px ${props => rgba(props.theme.colorDarkGray, .5)};

    border-collapse: collapse;
    border-radius: 12px;

    overflow: hidden;

    thead {
        tr {
            th {
                padding: 7px;

                text-align: left;
                vertical-align: middle;

                font-size: 15px;
                color: ${props => props.theme.colorVioletGray} !important;

                background-color: ${props => props.theme.colorLightPrimary};

                &.has-sort {
                    span {
                        width: 100%;

                        display: block;
                        position: relative;

                        border: none;
                        cursor: pointer;

                        padding-right: 16px;

                        &::before {
                            content: '';
                            width: 10px;
                            height: 10px;

                            position: absolute;
                            top: calc(50% - 10px);
                            right: 0;
                            
                            background-image: url(${AngleUpIcon});
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;

                            transition: .2s all ease-in-out;
                            opacity: .4;
                        }

                        &::after {
                            content: '';
                            width: 10px;
                            height: 10px;

                            position: absolute;
                            top: calc(50% + 2px);
                            right: 0;
                            
                            background-image: url(${AngleDownIcon});
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;

                            transition: .2s all ease-in-out;
                            opacity: .4;
                        }
                    }
                    &.asc {
                        span {
                            &::before {
                                opacity: 1;
                            }
                        }
                    }
                    &.desc {
                        span {
                            &::after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    tbody {
        tr {
            &:nth-child(even) {
                td {
                    background-color: ${props => rgba(props.theme.colorDarkGray, .2)};
                }
            }

            &.task-tr {
                &.initiated {

                }
                &.pending {
                    td {
                        color: ${props => props.theme.colorPrimary};
                    }
                }
                &.completed {
                    td {
                        color: ${props => props.theme.colorGreen};
                    }
                }
                &.outdated {
                    td {
                        color: ${props => props.theme.colorRed};
                    }
                }
            }

            td {
                padding: 7px;

                vertical-align: middle;

                color: ${props => props.theme.colorDark};
            }
        }
    }
`;