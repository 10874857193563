import * as actionTypes from "../actionTypes";

import { updateState } from "../utils";

const initialState = {
    companies: null,
    currentCompany: null,
    allMembers: null,
    members: null,
    totalMembers: 0,
    memberFilter: {
        page: 1,
        searchKey: "",
        excludeTeam: null,
        showMe: false,
    },
    invitations: null,
    totalInvitations: 0,
    invitationFilter: {
        page: 1,
        searchKey: "",
    },
    allTeams: null,
    teams: null,
    totalTeams: 0,
    teamFilter: {
        page: 1,
        searchKey: "",
    },
};

const updateCompanies = (state, action) => {
    return updateState(
        state,
        {
            companies: action.companies,
        }
    );
};

const updateCurrentCompany = (state, action) => {
    return updateState(
        state,
        {
            currentCompany: action.currentCompany,
        }
    );
};

const updateAllMembers = (state, action) => {
    return updateState(
        state,
        {
            allMembers: action.allMembers,
        }
    );
};

const updateMembers = (state, action) => {
    return updateState(
        state,
        {
            members: action.members,
            totalMembers: action.totalMembers,
        }
    );
};

const updateMemberFilter = (state, action) => {
    return updateState(
        state,
        {
            memberFilter: action.memberFilter,
        }
    );
};

const updateInvitations = (state, action) => {
    return updateState(
        state,
        {
            invitations: action.invitations,
            totalInvitations: action.totalInvitations,
        }
    );
};

const updateInvitationFilter = (state, action) => {
    return updateState(
        state,
        {
            invitationFilter: action.invitationFilter,
        }
    );
};

const updateAllTeams = (state, action) => {
    return updateState(
        state,
        {
            allTeams: action.allTeams,
        }
    );
};

const updateTeams = (state, action) => {
    return updateState(
        state,
        {
            teams: action.teams,
            totalTeams: action.totalTeams,
        }
    );
};

const updateTeamFilter = (state, action) => {
    return updateState(
        state,
        {
            teamFilter: action.teamFilter,
        }
    );
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.UPDATE_COMPANIES: return updateCompanies(state, action);
    case actionTypes.UPDATE_CURRENT_COMPANY: return updateCurrentCompany(state, action);
    case actionTypes.UPDATE_ALL_MEMBERS: return updateAllMembers(state, action);
    case actionTypes.UPDATE_MEMBERS: return updateMembers(state, action);
    case actionTypes.UPDATE_MEMBER_FILTER: return updateMemberFilter(state, action);
    case actionTypes.UPDATE_INVITATIONS: return updateInvitations(state, action);
    case actionTypes.UPDATE_INVITATION_FILTER: return updateInvitationFilter(state, action);
    case actionTypes.UPDATE_ALL_TEAMS: return updateAllTeams(state, action);
    case actionTypes.UPDATE_TEAMS: return updateTeams(state, action);
    case actionTypes.UPDATE_TEAM_FILTER: return updateTeamFilter(state, action);
    default: return state;
    }
};

export default reducer;
