export const UPDATE_LOADING = "UPDATE_LOADING";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";

export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";

export const UPDATE_COMPANIES = "UPDATE_COMPANIES";
export const UPDATE_CURRENT_COMPANY = "UPDATE_CURRENT_COMPANY";
export const UPDATE_ALL_MEMBERS = "UPDATE_ALL_MEMBERS";
export const UPDATE_MEMBERS = "UPDATE_MEMBERS";
export const UPDATE_MEMBER_FILTER = "UPDATE_MEMBER_FILTER";
export const UPDATE_INVITATIONS = "UPDATE_INVITATIONS";
export const UPDATE_INVITATION_FILTER = "UPDATE_INVITATION_FILTER";
export const UPDATE_ALL_TEAMS = "UPDATE_ALL_TEAMS";
export const UPDATE_TEAMS = "UPDATE_TEAMS";
export const UPDATE_TEAM_FILTER = "UPDATE_TEAM_FILTER";

export const UPDATE_SCHEDULED_MEETINGS = "UPDATE_SCHEDULED_MEETINGS";
export const UPDATE_HELD_MEETINGS = "UPDATE_HELD_MEETINGS";
export const UPDATE_HELD_MEETING_FILTER = "UPDATE_HELD_MEETING_FILTER";
export const UPDATE_ALL_MEETINGS = "UPDATE_ALL_MEETINGS";

export const UPDATE_ALL_TOPICS = "UPDATE_ALL_TOPICS";
export const UPDATE_TOPICS = "UPDATE_TOPICS";
export const UPDATE_TOPIC_FILTER = "UPDATE_TOPIC_FILTER";

export const UPDATE_TASKS = "UPDATE_TASKS";
export const UPDATE_TASK_FILTER = "UPDATE_TASK_FILTER";
