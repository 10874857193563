import React, { useEffect } from "react";
import Loadable from "react-loadable";
import { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { ConfigProvider, notification } from "antd";
import en from "antd/locale/en_US";
import es from "antd/locale/es_ES";
import { Loader } from "./components/base";

import { styledTheme, theme } from "./utils/themes";
import { GOOGLE_CLIENT_ID } from "./utils/constants";
import { updateMessage } from "./services/store/actions/theme";

const scales = {
    en,
    es,
};

const loading = () => <><Loader /></>;

const Login = Loadable({
    loader: () => import("./pages/Auth/Login/Login"),
    loading,
});

const Register = Loadable({
    loader: () => import("./pages/Auth/Register/Register"),
    loading,
});

const Verify = Loadable({
    loader: () => import("./pages/Auth/Verify/Verify"),
    loading,
});

const ForgetPassword = Loadable({
    loader: () => import("./pages/Auth/ForgetPassword/ForgetPassword"),
    loading,
});

const MeetingResume = Loadable({
    loader: () => import("./pages/External/Meeting/Resume"),
    loading,
});

const MainLayout = Loadable({
    loader: () => import("./layouts/MainLayout/MainLayout"),
    loading,
});

function App() {

    const dispatch = useDispatch();
    const { t, i18n: i18nHook } = useTranslation();

    const message = useSelector((state) => state.theme.message);

    useEffect(() => {
        if (message.content !== null) {
            notification[message.type]({
                message: t(message.type).toUpperCase(),
                description: message.content,
                className: theme,
                onClose: () => dispatch(updateMessage(null, null)),
            });
        }
    }, [message.content]);

    return (
        <ConfigProvider theme={theme} locale={scales[i18nHook.language] || es}>
            <ThemeProvider theme={styledTheme}>
                <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/verify" element={<Verify />} />
                            <Route path="/forget-password" element={<ForgetPassword />} />
                            <Route path="/meeting-resume/:token" element={<MeetingResume />} />
                            <Route path="/*" element={<MainLayout />} />
                        </Routes>
                    </BrowserRouter>
                </GoogleOAuthProvider>
            </ThemeProvider>
        </ConfigProvider>
    );
}

export default App;
