import dayjs from "dayjs";

export const checkValidEmail = (email) => {
    const regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
};

export const getErrorMessage = e => {
    if (e.message === "Network Error") {
        return e.message;
    } else {
        if (e.response) {
            if (e.response.status === 401) {
                return "You are not authenticated, please login again!";
            } else if (e.response.status === 403) {
                return "You have no permission!";
            } else if (e.response.status === 404) {
                return "URL not found";
            } else if (e.response.status === 405) {
                return "Bad request";
            } else if (e.response.status === 500) {
                return "API error";
            } else {
                if (e.response?.data?.detail) {
                    return e.response.data.detail;
                } else {
                    return "Bad request";
                }
            }
        } else {
            return "API error";
        }
    }
};

export const durationToMins = (duration) => Math.ceil(duration / 60);

export const filterTextHandler = (text, searchKey) => {
    return text
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .indexOf(searchKey.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) !== -1;
};

export const filterSelectOptionHandler = (input, option) => filterTextHandler(option.children, input);

export const fileToBase64Handler = (file) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
    });
};

export const getParticipantName = (participant) => {
    return (
        participant.fullname ||
        participant.email
    );
};

export const blobToBase64 = (blob) => {
    return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            resolve(reader.result);
        };
    });
};

export const getTaskStatus = (task) => {
    let { status } = task;
    if (status === "completed") {
        return status;
    }
    if (new Date(task.deadline) < new Date()) {
        return "outdated";
    }
    return status;
};

export const getDuration = (startedAt, finishedAt, unit = "minutes") => {
    const date1 = startedAt ? dayjs(startedAt) : dayjs();
    const date2 = finishedAt ? dayjs(finishedAt) : dayjs();
    return date2.diff(date1, unit);
};
