import { Steps } from "antd";

import styled from "styled-components";

export const EMSteps = styled(Steps)`
    .ant-steps-item {
        padding-inline-start: 0 !important;

        .ant-steps-item-container {
            .ant-steps-item-icon {
                /* width: 24px;
                height: 24px;

                font-size: 12px;
                line-height: 24px; */
                margin-inline: 0;
            }

            .ant-steps-item-content {
                .ant-steps-item-title {
                    &::after {
                        height: 4px;

                        left: 0;
                        top: 10px;
                    }
                }
            }
        }
    }
`;
