export const TRANSLATIONS_EN = {
    // COMMON
    fieldRequired: "This field is required",
    fieldInvalid: "This field is invalid",
    emailDuplicated: "This email is already used",
    confirmPasswordCorrectly: "Confirm password correctly",

    success: "Success",
    error: "Error",
    noData: "No data",

    add: "Add",
    create: "Create",
    edit: "Edit",
    save: "Save",
    resend: "Resend",
    delete: "Delete",
    cancel: "Cancel",
    confirm: "Confirm",
    remove: "Remove",
    close: "Close",

    argentina: "Argentina",
    chile: "Chile",

    // LOGIN
    email: "Email",
    password: "Password",
    keepMeSignedIn: "Keep me signed in",
    forgetPassword: "Forget password",
    signIn: "Sign In",
    signInWithGoogle: "Sign In with Google",
    stillHaveNoAccount: "Still have no account",
    signUp: "Sign Up",

    // REGISTER
    repeatPassword: "Repeat password",
    fullname: "Full name",
    signUpWithGoogle: "Sign Up with Google",
    alreadyHaveAccount: "Already have an account",

    // VERIFY ACCOUNT
    verify: "Verify",
    resendCode: "Resend Code",

    // FORGET PASSWORD
    code: "Code",
    sendCode: "Send code",
    resetPassword: "Reset password",
    backToSignIn: "Back to Sign In",

    // MAIN LAYOUT
    noCompanySelected: "No company selected, please select company first.",

    welcomeUser: "Welcome {{name}}",
    profile: "Profile",
    signOut: "Sign Out",
    searchPersonTaskMeeting: "Search persons, tasks, meetings",
    members: "member(s)",
    addTeam: "Add team",
    home: "Home",
    meetings: "Meetings",
    tasks: "Tasks",
    calendar: "Calendar",
    configuration: "Configuration",

    // COMPANY
    selectCompany: "Select company",
    createCompany: "Create company",
    manageCurrentCompany: "Manage current company",
    manageCompany: "Manage company",
    companyName: "Company name",
    companyEmail: "Company email",
    country: "Country",
    postalCode: "Postal code",
    companyCreateSucceeded: "Company creation succeeded",
    companyEditSucceeded: "Company edition succeeded",
    generalInformation: "General information",
    companyMembers: "Company members",
    pendingInvitations: "Pending invitations",
    phone: "Phone",
    role: "Role",
    roleName1: "Admin",
    roleName2: "Coach",
    roleName3: "Member",
    searchByName: "Search by name",
    searchByNameOrEmail: "Search by name or email",
    sendNewInvitation: "Send new invitation",
    sendInvitation: "Send invitation",
    sendingInvitationSucceeded: "Sending invitation succeeded",
    areYouSureToDeleteThisInvitation: "Are you sure to delete this invitation",
    deletingInvitationSucceeded: "Deleting invitation succeeded",
    areYouSureToRemoveThisUserFromCompany: "Are you sure to remove this user from company",
    updateMemberSucceeded: "Updating member succeeded",
    deletingMemberSucceeded: "Deleting member succeeded",

    // TEAM
    teams: "Teams",
    createTeam: "Create team",
    teamName: "Team name",
    manageTeam: "Manage team",
    teamMembers: "Team members",
    teamCreateSucceeded: "Team create succeeded",
    areYouSureToDeleteThisTeam: "Are you sure to delete this team",
    member: "Member",
    teamUpdateSucceeded: "Update team succeeded",
    deletingTeamSucceeded: "Deleting team succeeded",
    addMembers: "Add members",
    areYouSureToRemoveThisUserFromTeam: "Are you sure to remove this user from team",

    // MEETINGS
    meeting: "Meeting",
    newMeeting: "New meeting",
    noMeeting: "No meeting",
    editMeeting: "Edit meeting",
    scheduledMeetings: "Scheduled meetings",
    heldMeetings: "Held meetings",
    virtual: "Virtual",
    name: "Name",
    color: "Color",
    location: "Location",
    startAt: "Start at",
    duration: "Duration",
    noRepeat: "No repeat",
    repeatType: "Repeat type",
    repeatNumber: "Repeat number",
    meetingInformation: "Meeting information",
    team: "Team",
    meetingCreateSucceeded: "Meeting creation succeeded",
    pleaseSelectTopicsFromHere: "Please select topics from here",
    selectedTopics: "Selected topics",
    addInvitees: "Add invitees",

    equipment: "Equipment",
    participants: "Participants",
    persons: "Persons",
    date: "Date",
    estimatedTime: "Estimated time",
    addParticipants: "Add participants",
    addTopics: "Add topics",
    addTasks: "Add tasks",
    startMeeting: "Start meeting",
    meetingStep_1: "Check in",
    meetingStep_4: "Review tasks",
    meetingStep_5: "Manage topics",
    meetingStep_6: "Manage tasks",
    meetingStep_7: "Check out",
    participant: "Participant",
    clickToStartRecording: "Click to start recording",
    recorded: "Recorded",
    continue: "Continue",
    next: "Next",
    responsible: "Responsible",
    finishDate: "Finish date",
    status: "Status",
    resolved: "Resolved",
    back: "Back",
    start: "Start",
    finish: "Finish",
    view: "View",
    estimatedDuration: "Estimated duration",
    orderOfTheDay: "Order of the day",
    estimation: "Estimation",
    updateEstimation: "Update estimation",
    unresolved: "Unresolved",
    isDefault: "Is default",
    isResolved: "Is resolved",
    areYouSureToDeleteMeeting: "Are you sure to delete this meeting?",
    noTopics: "No topics",
    saveContent: "Save content",
    finishTopic: "Finish topic",
    summarize: "Summarize",
    conversation: "Conversation",
    generateSummarize: "Generate summarize",
    clearRecording: "Clear recording",
    setStatus: "Set status",
    present: "Present",
    absent: "Absent",
    late: "Late",
    excused_absent: "Excused absence",
    leave_early: "Leave early",
    startedAt: "Started at",
    finishedAt: "Finished at",
    host: "All",
    defaultTopics: "Default topics",

    meetingResultReceivers: "Meeting result receivers",
    noParticipants: "No participants",
    noResumeReceivers: "No resume receivers",
    newReceiver: "New receiver",
    sendEmail: "Send email",
    editReceiver: "Edit receiver",
    areYouSureToDeleteThisResumeReceiver: "Are you sure to delete this resume receiver?",
    areYouSureToSendMeetingResumeEmail: "Are you sure to send meeting resume email?",
    send: "Send",
    meetingResult: "Meeting result",
    meetingName: "Meeting Name",
    punctuality: "Punctuality",
    presenteeism: "Presenteeism",
    performance: "Performance",
    diary: "Diary",
    compliance: "Compliance",
    company: "Company",
    scheduled: "Scheduled",
    estimated: "Estimated",
    real: "Real",
    net: "Net",
    averageMood: "Average mood",
    checkIn: "Check in",
    checkOut: "Check out",
    evaluation: "Evaluation",
    attendance: "Attendance",
    steps: "Steps",
    requestedDate: "Requested date",
    partial: "Partial",
    accumulated: "Accumulated",

    // TOPICS
    topics: "Topics",
    newTopic: "New topic",
    editTopic: "Edit topic",
    title: "Title",
    time: "Time",
    confirmDeleteTopic: "Are you sure to delete this topic?",
    createdBy: "Created by",
    requestedBy: "Requested by",
    decisionType: "Decision type",
    consensus: "Consensus",
    majority: "Majority",
    leader: "Leader",
    unanimity: "Unanimity",
    vote: "Vote",
    consultation: "Consultation",
    does_not_require: "Does not require",
    createdAt: "Created at",
    topicConversationHistory: "Topic conversation history",

    // TASKS
    newTask: "New task",
    editTask: "Edit task",
    coResponsible: "Co-responsible",
    deadline: "Deadline",
    topic: "Topic",
    task: "Task",
    description: "Description",
    initiated: "Todo",
    pending: "In progress",
    completed: "Completed",
    outdated: "Outdated",
    noTasks: "No tasks",
    viewHistory: "View history",
    taskHistory: "Task history",
    noHistory: "No history",
    user: "User",
    taskCreatedDescription: "Task created",
    taskStatusUpdatedDescription: "Status updated from `{{oldStatus}}` to `{{newStatus}}`",
    taskDeadlineUpdatedDescription: "Deadline updated from `{{- oldDate}}` to `{{- newDate}}`",
    searchByNameOrDescription: "Search by title or description",
    filterByUsers: "Filter by users",
    filterByStatus: "Filter by status",

    // CALENDAR
    today: "Today",
    month: "Month",
    week: "Week",
    day: "Day",
    year: "Year",

    // PROFILE
    birthday: "Birthday",
    avatar: "Avatar",
    selectAvatar: "Select avatar",
};