import { combineReducers } from "redux";

import theme from "./theme";
import constants from "./constants";
import auth from "./auth";
import company from "./company";
import meeting from "./meeting";
import topic from "./topic";
import task from "./task";

export default combineReducers({
    theme,
    constants,
    auth,
    company,
    meeting,
    topic,
    task,
});
