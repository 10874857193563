import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { rgba } from "polished";

import { Card } from "antd";

const props = {
    children: PropTypes.any,
    className: PropTypes.string,

    border: PropTypes.string,
    background: PropTypes.string,
    shadow: PropTypes.bool,
    padding: PropTypes.number,
};

const EMCard = ({ children, className, border, background, shadow, padding = 24 }) => {

    return (
        <Wrapper
            className={className}
            border={border}
            background={background}
            shadow={(shadow || false).toString()}
            padding={padding}
        >
            {children}
        </Wrapper>
    );
};

const Wrapper = styled(Card)`
    border: ${props => props.border ? `1px solid ${props.border}` : "none"};
    box-shadow: ${props => props.shadow === "true" ? `0 5px 8px ${rgba(props.theme.colorDarkGray, .5)}` : "none"};
    border-radius: 12px;

    overflow: hidden;

    .ant-card-body {
        padding: ${props => props.padding !== undefined ? props.padding : "24"}px;
        background: ${(props) => props.background !== undefined ? props.background : props.theme.colorWhite};
        border-radius: 12px;
    }
`;

EMCard.propTypes = props;

export default EMCard;