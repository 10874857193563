import styled from "styled-components";

export const EMTableV2 = styled.table`
    width: 100%;

    border-collapse: collapse;

    overflow: hidden;

    thead {
        border-bottom: 1px solid ${props => props.theme.colorVioletGray} !important;

        tr {
            th {
                padding: 5px 5px 5px 0;

                text-align: left;
                vertical-align: middle;

                font-size: 14px;
                font-weight: 600;
                color: ${props => props.theme.colorVioletGray} !important;
            }
        }
    }

    tbody {
        tr {
            &.task-tr {
                &.initiated {

                }
                &.pending {
                    td {
                        color: ${props => props.theme.colorPrimary} !important;
                    }
                }
                &.completed {
                    td {
                        color: ${props => props.theme.colorGreen} !important;
                    }
                }
                &.outdated {
                    td {
                        color: ${props => props.theme.colorRed} !important;
                    }
                }
            }
            
            td {
                padding: 5px 5px 5px 0;

                vertical-align: ${(props) => props.verticalAlign || "middle"};

                font-size: 14px;
                font-weight: 400;
                color: ${props => props.theme.colorVioletGray} !important;
            }
        }
    }
`;