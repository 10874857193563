import * as actionTypes from "../actionTypes";

import { updateState } from "../utils";

const initialState = {
    allTopics: null,
    topics: null,
    totalTopics: 0,
    filter: {
        page: 1,
        searchKey: "",
    },
};

const updateAllTopics = (state, action) => {
    return updateState(
        state,
        {
            allTopics: action.allTopics,
        }
    );
};

const updateTopics = (state, action) => {
    return updateState(
        state,
        {
            topics: action.topics,
            totalTopics: action.totalTopics,
        }
    );
};

const updateFilter = (state, action) => {
    return updateState(
        state,
        {
            filter: action.filter,
        }
    );
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.UPDATE_ALL_TOPICS: return updateAllTopics(state, action);
    case actionTypes.UPDATE_TOPICS: return updateTopics(state, action);
    case actionTypes.UPDATE_TOPIC_FILTER: return updateFilter(state, action);
    default: return state;
    }
};

export default reducer;
