import * as actionTypes from "../actionTypes";
import { updateState } from "../utils";

const initialState = {
    scheduledMeetings: null,
    heldMeetings: null,
    heldMeetingTotal: 0,
    heldMeetingFilter: {
        page: 1,
        sortKey: null,
        sortDirection: null,
    },
    allMeetings: null,
};

const updateScheduledMeetings = (state, action) => {
    return updateState(
        state,
        {
            scheduledMeetings: action.scheduledMeetings,
        }
    );
};

const updateHeldMeetings = (state, action) => {
    return updateState(
        state,
        {
            heldMeetings: action.heldMeetings,
            heldMeetingTotal: action.heldMeetingTotal,
        }
    );
};

const updateHeldMeetingFilter = (state, action) => {
    return updateState(
        state,
        {
            heldMeetingFilter: action.heldMeetingFilter,
        }
    );
};

const updateAllMeetings = (state, action) => {
    return updateState(
        state,
        {
            allMeetings: action.allMeetings,
        }
    );
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.UPDATE_SCHEDULED_MEETINGS: return updateScheduledMeetings(state, action);
    case actionTypes.UPDATE_HELD_MEETINGS: return updateHeldMeetings(state, action);
    case actionTypes.UPDATE_HELD_MEETING_FILTER: return updateHeldMeetingFilter(state, action);
    case actionTypes.UPDATE_ALL_MEETINGS: return updateAllMeetings(state, action);
    default: return state;
    }
};

export default reducer;
