import {
    emColorBlack,
    emColorDark,
    emColorDarkGray,
    emColorError,
    emColorGray,
    emColorLightPrimary,
    emColorPrimary,
    emColorVioletGray,
    emColorWhite,
    emColorSuccess,
} from "./constants";

export const theme = {
    token: {
        colorPrimary: emColorPrimary,
        colorLink: emColorPrimary,
        colorBgLayout: emColorWhite,
        colorTextBase: emColorDark,
        colorText: emColorDark,

        controlHeight: 36,

        fontFamily: "Roboto",

    },
};

export const styledTheme = {
    colorPrimary: emColorPrimary,
    colorLightPrimary: emColorLightPrimary,
    colorGreen: emColorSuccess,
    colorRed: emColorError,

    colorWhite: emColorWhite,
    colorGray: emColorGray,
    colorDarkGray: emColorDarkGray,
    colorVioletGray: emColorVioletGray,
    colorDark: emColorDark,
    colorBlack: emColorBlack,

    antdBorderColor: "#d9d9d9",
    antdInputHoverBorderColor: "#928aff",
    antdInputFocusBoxShadow: "rgb(55 5 255 / 6%)",
    antdInputColor: "rgba(0, 0, 0, 0.88)",
};
