import axios from "axios";

import * as actionTypes from "../actionTypes";
import { errorManageHandler, updateLoading } from "./theme";

import { API_BASE_URL, LS_AUTH_TOKEN_KEY } from "../../../utils/constants";

export const updateToken = (token, keepToken = false) => {
    if (keepToken) {
        localStorage.setItem(LS_AUTH_TOKEN_KEY, token);
    }

    return {
        type: actionTypes.UPDATE_TOKEN,
        token,
    };
};

const updateUserData = (userData) => {
    return {
        type: actionTypes.UPDATE_USER_DATA,
        userData,
    };
};


export const fetchUserData = () => {
    return (dispatch, getState) => {
        const token = getState().auth.token;
        axios
            .get(
                `${API_BASE_URL}/auth/user`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            .then((res) => {
                dispatch(updateUserData(res.data));
            })
            .catch((e) => {
                dispatch(errorManageHandler(e));
            });
    };
};

export const editUserData = (data, callback) => {
    return (dispatch, getState) => {
        const token = getState().auth.token;
        dispatch(updateLoading(true));
        axios
            .put(
                `${API_BASE_URL}/auth/user`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            .then((res) => {
                dispatch(updateUserData(res.data));
                if (callback) callback();
                dispatch(updateLoading(false));
            })
            .catch((e) => {
                dispatch(errorManageHandler(e));
            });
    };
};

export const logout = () => {
    return async () => {
        localStorage.removeItem(LS_AUTH_TOKEN_KEY);
        window.open("/login", "_parent");
    };
};
