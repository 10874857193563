import React from "react";
import styled from "styled-components";

import Loader from "./Loader";

const InitLoader = () => {

    return (
        <Wrapper>
            <Loader />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-color: rgba(255, 255, 255, .75);

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 9999;
`;

export default InitLoader;