/** ------------------- */
// ENV VARIABLES
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

/** ------------------- */
// COLORS
export const emColorWhite = "#FFF";
export const emColorGray = "#F1F0F3";
export const emColorDarkGray = "#D1D1D1";
export const emColorVioletGray = "#6C6685";
export const emColorDark = "#212121";
export const emColorBlack = "#000";

export const emColorError = "#FF4D4F";
export const emColorPrimary = "#625DF5";
export const emColorLightPrimary = "#E1E1F7";
export const emColorSuccess = "#00BC8F";
export const emColorWarning = "#FE991C";

/** ------------------- */
// STATIC MESSAGES
export const msgUserVerifyCodeSentSuccessfully = "Verification code was sent successfully";

/** ------------------- */
// LOCALSTORAGE KEYS
export const LS_VERIFY_EMAIL_KEY = "verify_email";
export const LS_AUTH_TOKEN_KEY = "auth_token";

export const LS_SAVED_COMPANY_ID_KEY = "saved_company_id";

/** ------------------- */
// SYSTEM CONSTANTS
export const userCompanyRoles = [1, 2, 3];

export const countries = ["argentina", "chile"];

export const repeatTypes = ["day", "week", "month", "year"];

export const meetingStepKeys = [1, 2, 3, 4, 5, 6, 7];

export const topicDecisionTypes = [
    {
        value: "consensus",
        label: "Consensus",
    },
    {
        value: "majority",
        label: "Majority",
    },
    {
        value: "leader",
        label: "Leader",
    },
    {
        value: "unanimity",
        label: "Unanimity",
    },
    {
        value: "vote",
        label: "Vote",
    },
    {
        value: "consultation",
        label: "Consultation",
    },
    {
        value: "does_not_require",
        label: "Does not require",
    },
];

export const taskStatuses = [
    "initiated",
    "pending",
    "completed",
];

export const taskStatusColorObj = {
    "initiated": "",
    "pending": emColorPrimary,
    "completed": emColorSuccess,
    "outdated": emColorError,
};
