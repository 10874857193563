export const TRANSLATIONS_ES = {
    // COMMON
    fieldRequired: "Este campo es obligatorio",
    fieldInvalid: "Este campo es inválido",
    emailDuplicated: "Este correo electrónico ya está en uso",
    confirmPasswordCorrectly: "Confirma la contraseña correctamente",

    success: "Éxito",
    error: "Error",
    noData: "No hay datos",

    add: "Agregar",
    create: "Crear",
    edit: "Editar",
    save: "Guardar",
    resend: "Reenviar",
    delete: "Eliminar",
    cancel: "Cancelar",
    confirm: "Confirmar",
    remove: "Eliminar",
    close: "Cerrar",

    argentina: "Argentina",
    chile: "Chile",

    // LOGIN
    email: "Correo electrónico",
    password: "Contraseña",
    keepMeSignedIn: "Mantener sesión iniciada",
    forgetPassword: "Olvidé mi contraseña",
    signIn: "Iniciar sesión",
    signInWithGoogle: "Iniciar sesión con Google",
    stillHaveNoAccount: "Aún no tienes una cuenta",
    signUp: "Registrarse",

    // REGISTER
    repeatPassword: "Repetir contraseña",
    fullname: "Nombre completo",
    signUpWithGoogle: "Registrarse con Google",
    alreadyHaveAccount: "¿Ya tienes una cuenta?",

    // VERIFY ACCOUNT
    verify: "Verificar",
    resendCode: "Reenviar código",

    // FORGET PASSWORD
    code: "Código",
    sendCode: "Enviar código",
    resetPassword: "Restablecer contraseña",
    backToSignIn: "Volver a iniciar sesión",

    // MAIN LAYOUT
    noCompanySelected: "No se ha seleccionado ninguna empresa, por favor selecciona una empresa primero.",

    welcomeUser: "Bienvenido(a), {{name}}",
    profile: "Perfil",
    signOut: "Cerrar sesión",
    searchPersonTaskMeeting: "Buscar personas, tareas, reuniones",
    members: "miembro(s)",
    addTeam: "Agregar equipo",
    home: "Inicio",
    meetings: "Reuniones",
    tasks: "Tareas",
    calendar: "Calendario",
    configuration: "Configuración",

    // COMPANY
    selectCompany: "Seleccionar empresa",
    createCompany: "Crear empresa",
    manageCurrentCompany: "Administrar empresa actual",
    manageCompany: "Administrar empresa",
    companyName: "Nombre de la empresa",
    companyEmail: "Correo electrónico de la empresa",
    country: "País",
    postalCode: "Código postal",
    companyCreateSucceeded: "La creación de la empresa fue exitosa",
    companyEditSucceeded: "La edición de la empresa fue exitosa",
    generalInformation: "Información general",
    companyMembers: "Miembros de la empresa",
    pendingInvitations: "Invitaciones pendientes",
    phone: "Teléfono",
    role: "Rol",
    roleName1: "Admin",
    roleName2: "Coach",
    roleName3: "Miembro",
    searchByName: "Buscar por nombre",
    searchByNameOrEmail: "Buscar por nombre o correo electrónico",
    sendNewInvitation: "Enviar nueva invitación",
    sendInvitation: "Enviar invitación",
    sendingInvitationSucceeded: "La invitación se envió correctamente",
    areYouSureToDeletethisInvitation: "¿Estás seguro de eliminar esta invitación?",
    deletingInvitationSucceeded: "La eliminación de la invitación fue exitosa",
    areYouSureToRemoveThisUserFromCompany: "¿Estás seguro de eliminar a este usuario de la empresa?",
    updateMemberSucceeded: "La actualización del miembro fue exitosa",
    deletingMemberSucceeded: "La eliminación del miembro fue exitosa",

    // TEAM
    teams: "Equipos",
    createTeam: "Crear equipo",
    teamName: "Nombre del equipo",
    manageTeam: "Administrar equipo",
    teamMembers: "Miembros del equipo",
    teamCreateSucceeded: "La creación del equipo fue exitosa",
    areYouSureToDeleteThisTeam: "¿Estás seguro de eliminar este equipo?",
    member: "Miembro",
    teamUpdateSucceeded: "La actualización del equipo fue exitosa",
    deletingTeamSucceeded: "La eliminación del equipo fue exitosa",
    addMembers: "Agregar miembros",
    areYouSureToRemoveThisUserFromTeam: "¿Estás seguro de eliminar a este usuario del equipo?",

    // MEETINGS
    meeting: "Meeting",
    newMeeting: "Nueva reunión",
    noMeeting: "No hay reuniones",
    editMeeting: "Editar reunión",
    scheduledMeetings: "Reuniones programadas",
    heldMeetings: "Reuniones realizadas",
    virtual: "Virtual",
    name: "Nombre",
    color: "Color",
    location: "Ubicación",
    startAt: "Comienza a las",
    duration: "Duración",
    noRepeat: "No repetir",
    repeatType: "Tipo de repetición",
    repeatNumber: "Número de repeticiones",
    meetingInformation: "Información de la reunión",
    team: "Equipo",
    meetingCreateSucceeded: "La creación de la reunión fue exitosa",
    pleaseSelectTopicsFromHere: "Por favor, selecciona temas de aquí",
    selectedTopics: "Temas seleccionados",
    addInvitees: "Add invitees",

    equipment: "Equipo",
    participants: "Participantes",
    persons: "Personas",
    date: "Fecha",
    estimatedTime: "Tiempo estimado",
    addParticipants: "Agregar participantes",
    addTopics: "Agregar temas",
    addTasks: "Agregar tareas",
    startMeeting: "Iniciar",
    meetingStep_1: "Check-in",
    meetingStep_4: "Revisar tareas",
    meetingStep_5: "Administrar temas",
    meetingStep_6: "Administrar tareas",
    meetingStep_7: "Check-out",
    participant: "Participante",
    clickToStartRecording: "Haz clic para comenzar la grabación",
    recorded: "Grabado",
    continue: "Continuar",
    next: "Siguiente",
    responsible: "Responsable",
    finishDate: "Fecha de finalización",
    status: "Estado",
    resolved: "Resuelto",
    back: "Atrás",
    start: "Comenzar",
    finish: "Finalizar",
    view: "Ver",
    estimatedDuration: "Estimado",
    orderOfTheDay: "Orden del día",
    estimation: "Estimación",
    updateEstimation: "Actualizar estimación",
    unresolved: "Sin resolver",
    isDefault: "Is default",
    isResolved: "Está resuelto",
    areYouSureToDeleteMeeting: "¿Estás seguro de eliminar esta reunión?",
    noTopics: "No hay temas",
    saveContent: "Guardar contenido",
    finishTopic: "Finalizar tema",
    summarize: "Resumir",
    conversation: "Conversación",
    generateSummarize: "Generar resumen",
    clearRecording: "Borrar grabación",
    setStatus: "Establecer estado",
    present: "Presente",
    absent: "Ausente",
    late: "Tarde",
    excused_absent: "Excused absence",
    leave_early: "Leave early",
    startedAt: "Started at",
    finishedAt: "Finished at",
    host: "Todos",
    defaultTopics: "Default topics",

    meetingResultReceivers: "Meeting result receivers",
    noParticipants: "No participants",
    noResumeReceivers: "No resume receivers",
    sendEmail: "Send email",
    newReceiver: "New receiver",
    areYouSureToDeleteThisResumeReceiver: "Are you sure to delete this resume receiver?",
    areYouSureToSendMeetingResumeEmail: "Are you sure to send meeting resume email?",
    send: "Send",
    meetingResult: "Meeting result",
    meetingName: "Meeting Name",
    punctuality: "Punctuality",
    presenteeism: "Presenteeism",
    performance: "Performance",
    diary: "Diary",
    compliance: "Compliance",
    company: "Company",
    scheduled: "Scheduled",
    estimated: "Estimated",
    real: "Real",
    net: "Net",
    averageMood: "Average mood",
    checkIn: "Check in",
    checkOut: "Check out",
    evaluation: "Evaluation",
    attendance: "Attendance",
    steps: "Steps",
    requestedDate: "Requested date",
    partial: "Partial",
    accumulated: "Accumulated",

    // TOPICS
    topics: "Temas",
    newTopic: "Nuevo tema",
    editTopic: "Editar tema",
    title: "Título",
    time: "Tiempo",
    confirmDeleteTopic: "¿Está seguro de eliminar este tema?",
    createdBy: "Creado por",
    requestedBy: "Solicitado por",
    decisionType: "Tipo de Decisión",
    consensus: "Consenso",
    majority: "Mayoría",
    leader: "Líder",
    unanimity: "Unanimidad",
    vote: "Votación",
    consultation: "Consulta",
    does_not_require: "No require",
    createdAt: "Creado el",
    topicConversationHistory: "Topic conversation history",

    // TASKS
    newTask: "Nueva tarea",
    editTask: "Editar tarea",
    coResponsible: "Co-responsable",
    deadline: "Fecha límite",
    topic: "Tema",
    task: "Task",
    description: "Descripción",
    initiated: "Por hacer",
    pending: "En progreso",
    completed: "Completada",
    outdated: "Vencida",
    noTasks: "No hay tareas",
    viewHistory: "Ver historial",
    taskHistory: "Historial de tareas",
    noHistory: "No hay historial",
    user: "Usuario",
    taskCreatedDescription: "Task created",
    taskStatusUpdatedDescription: "Estado actualizado de '{{oldStatus}}' a '{{newStatus}}'",
    taskDeadlineUpdatedDescription: "Fecha límite actualizada de '{{- oldDate}}' a '{{- newDate}}'",
    searchByNameOrDescription: "Search by title or description",
    filterByUsers: "Filter by users",
    filterByStatus: "Filter by status",

    // CALENDAR
    today: "Hoy",
    month: "Mensual",
    week: "Semanal",
    day: "Diaria",
    year: "Anual",

    // PROFILE
    birthday: "Fecha de nacimiento",
    avatar: "Avatar",
    selectAvatar: "Seleccionar avatar",
};