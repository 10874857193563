import * as actionTypes from "../actionTypes";

import { updateState } from "../utils";

const initialState = {
    tasks: null,
    totalTasks: 0,
    filter: {
        page: 1,
        searchKey: "",
        members: [],
        statuses: [],
    },
};

const updateTasks = (state, action) => {
    return updateState(
        state,
        {
            tasks: action.tasks,
            totalTasks: action.totalTasks,
        }
    );
};

const updateFilter = (state, action) => {
    return updateState(
        state,
        {
            filter: action.filter,
        }
    );
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.UPDATE_TASKS: return updateTasks(state, action);
    case actionTypes.UPDATE_TASK_FILTER: return updateFilter(state, action);
    default: return state;
    }
};

export default reducer;
